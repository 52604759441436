:root {
  // - - - - - FONTS - - - - - //
  --base-font-family          : 'Roboto', sans-serif;
  --base-font-size            : 14px;
  --font-size-sm              : 12px;
  --base-font-weight          : 400;
  --base-line-height          : 145%;
  --base-font-color           : black;
  --base-body-color           : #F9F9F9;

  // - - - - - COLORS - - - - - //
  --grey-light                : #EFEFEF;
  --main-green-color          : #48E18F;
  --main-blue-color           : #6083FF;
  --main-pink-color           : #FD7F7F;
  --text-grey                 : #C4C4C4;

  // - - - - - COMPONENTS SIZES - - - - - //
  --scrollbar-width           : 5px;

  // - - - - - TRANSITION - - - - - //
}
