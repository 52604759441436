// TODO: refactor tooltip customization
.leaflet-tooltip {
  display: flex !important;
  gap: 5px !important;
  align-items: center !important;
  color: white !important;
  border: none !important;
  background-color: black !important;
  opacity: .7 !important;
  padding: 5px 10px !important;
}

.leaflet-tooltip::before {
  border-top-color: black !important;
}