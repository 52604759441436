.gm-style-iw-c {
  padding: 0!important;

  button {
    display: none !important;
  }
}

.gm-style-iw-d {
  overflow: initial !important;
}

.gm-style-iw-tc::after {
  background: black!important;
}